
<!--异常提报明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">异常提报</h2>
        </div>
        <div class="ph-right">
          <el-button size="small" type="primary" icon="el-icon-back" @click="back">返回</el-button>
        </div>
      </div>
      <div class="form-box" style="max-width: 1000px">
        <el-form
            ref="infoForm"
            :model="info"
            label-width="120px"
            label-position="left"
        >
          <el-form-item label="设备名称" prop="name">
            <div>{{info.name}}</div>
          </el-form-item>
          <el-form-item label="额定待机时长" prop="" style="max-width: 1000px">
            <div>{{info.ratedTime}}</div>
          </el-form-item>
          <el-form-item label="开始时间" prop="code">
            <div>{{info.startTime}}</div>
          </el-form-item>
          <el-form-item label="结束时间" prop="name">
            <div>{{info.endTime}}</div>
          </el-form-item>
          <el-form-item label="工作时长" prop="name">
            <div>{{info.workTime}}</div>
          </el-form-item>
          <el-form-item label="异常待机时长" prop="" style="max-width: 1000px">
            <div>{{info.actualTime}}</div>
          </el-form-item>
          <el-form-item label="提报原因" prop="code">
            <el-select v-model="info.reason" multiple :disabled="info.reason2 ? true : false"  clearable placeholder="请选择">
              <el-option
                  v-for="item in data"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="材料原因" prop="code" v-if="info.materialReason || (info.reason && info.reason.includes('材料原因'))">
            <el-select v-model="info.materialReason" clearable :disabled="info.materialReason2 ? true : false"  placeholder="请选择">
              <el-option
                  v-for="item in contentMaterialList"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="name">
            <el-input
                :disabled="info.remark2 ? true : false"
                v-model.trim="info.remark"
                maxlength="32"
                clearable
                placeholder="请填写备注"
                size="medium"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submit" v-if="!hide && !info.reason2" class="submit-btn">
              提交
            </el-button>
            <el-button type="primary" @click="handleSwitch" v-if="info.reason2 && info.nextState === 1" class="submit-btn">
              切换
            </el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/api'

export default {
  name: "abnormalReport",
  data() {
    return {
      hide: false,
      isUpdate: true,
      data: [],
      contentMaterialList: [
        '印版','纸张', '油墨', '胶辊', '橡皮布', '润版液','喷粉', '光油'
      ],
      info: {
        form: '',
        reason2: '',
        materialReason2: '',
        remark2: ''
      },
      form: {
        name: '',
        page: 1,
        count: 100000000,
        teamId: ''
      },
    }
  },
  created() {
    this.updateDevice()
  },
  methods: {
    back() {
      this.$router.push({ path: 'PrintHome'})
    },
    updateDevice() {
      API.getErrorDetail(this.$route.query.id).then(res => {
        console.log(res)
        if (res.message.success) {
          this.info = res.message.data;
          this.info.reason2 = res.message.data.reason;
          this.info.materialReason2 = res.message.data.materialReason;
          this.info.remark2 = res.message.data.remark;

          if (this.info.reason) {
            this.info.reason = this.info.reason.split(',')
          }
        } else {
          message('error',res.message.message)
        }
      })
      API.getReportReasonList().then(res => {
        this.data = res.message.data
      })
    },
    submit() {
      if (!this.info.reason) {
        this.$message({
          message: '请选择提报原因',
          type: 'warning',
        })
        return
      }
      for (let i = 0; i< this.info.reason.length; i++) {
        if (this.info.reason[i] === '材料原因' && !this.info.materialReason) {
          this.$message({
            message: '请选择材料原因',
            type: 'warning',
          })
          return;
        }
      }
      if (this.info.reason.includes('其他原因') && !this.info.remark) {
        this.$message({
          message: '请填写备注',
          type: 'warning',
        })
        return
      }
      const obj = {
        id: this.$route.query.id,
        reason: this.info.reason.join(';'),
        materialReason: this.info.materialReason,
        remark: this.info.remark
      }
      API.submitErrorReport(obj).then(res => {
        if (res.message.success) {
          this.$message({
            message: '提交成功',
            type: 'success',
          })
          this.updateDevice()
        } else {
          this.$message({
            message: response.message.message,
            type: response.message.success ? 'success' : 'error',
          })
        }
      })
    },
    handleSwitch() {
      API.getExcetionSwitch(this.$route.query.id).then(res => {
        if (res.message.success) {
          this.$message({
            message: '切换成功',
            type: 'success',
          })
          this.info.remark = ''
          // this.data = {}
          this.$router.push({ path: 'abnormalReportDetail', query: { id: res.message.data.id } });
          API.getErrorDetail(res.message.data.id).then(res => {
            console.log(res)
            if (res.message.success) {
              this.info = res.message.data;
              this.info.reason2 = res.message.data.reason;
              this.info.materialReason2 = res.message.data.materialReason;
              this.info.remark2 = res.message.data.remark;
              if (this.info.reason) {
                this.info.reason = this.info.reason.split(',')
              }
            } else {
              this.$message({
                message: res.message.message,
                type: 'error',
              })
            }
          })
        } else {
          this.$message({
            message: '暂无数据',
            type: 'error',
          })
          this.$router.go(-1);
        }
      })
    }
  }
}
</script>
<style>
.widthTable {
  width: 1550px;
}
.title {
  font-size: 18px;
  color: #3D3D3D;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

}
.title div:last-child{
  font-size: 16px;
  font-weight: 400;
}
.el-table .hidden-row {
  display: none;
}
</style>
